<script lang="ts">
	import IntersectionObserver from 'svelte-intersection-observer'
	import { page } from '$app/stores'
	import { HEAT_PUMP_LOCALES } from '$lib/routes'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let countries

	$: samePageRedirect = [HEAT_PUMP_LOCALES].find((route) => {
		return $page.url.pathname === `/${route[PUBLIC_WOLTAIR_WEB_CODE]}` ? route : false
	}) //allows redirecting to the same page in different language – just add the route to the array and export ROUTE_LOCALES from routes and import it here, default redirects to homepage

	const activeCountry = countries[PUBLIC_WOLTAIR_WEB_CODE]
	$: otherCountries = Object.entries(countries)
		.map(([key, value]) => {
			return key !== PUBLIC_WOLTAIR_WEB_CODE
				? {
						...value,
						url: samePageRedirect ? `${value.url}/${samePageRedirect[key]}` : value.url
				  }
				: null
		})
		.filter((value) => value)

	let showOtherCountries: boolean = false
	let isVisible: boolean = false
	let hovering: boolean

	let countryBtn: Element

	$: if (!isVisible) {
		showOtherCountries = false
	}
</script>

<svelte:window
	on:click={() => {
		if (!hovering) {
			showOtherCountries = false
		}
	}}
/>

<div
	class="relative mx-4 border-neutral-300 md:mx-0 md:border-r md:pl-1 md:pr-4"
	on:mouseenter={() => (hovering = true)}
	on:mouseleave={() => {
		hovering = false
	}}
>
	<IntersectionObserver element={countryBtn} bind:intersecting={isVisible}>
		<button
			class="arrow-after flex w-full items-center gap-3 whitespace-nowrap rounded-lg bg-neutral-300 p-3 text-xs text-neutral-600 hover:text-black md:w-fit md:bg-transparent md:p-0"
			on:click={() => (showOtherCountries = !showOtherCountries)}
			bind:this={countryBtn}
		>
			<img src={activeCountry.flag} alt={activeCountry.name} class="h-[1.5em]" />
			{activeCountry.name}
		</button>
	</IntersectionObserver>

	<div
		class="absolute inset-x-0 bottom-14 z-40 flex w-full min-w-fit flex-col gap-4 rounded-lg bg-white p-4 text-neutral-600 shadow-xl md:bottom-10"
		class:hidden={!showOtherCountries}
	>
		<!-- showing country names in original name -->
		{#each otherCountries as country}
			<a
				href={country.url}
				class="flex min-w-fit items-center gap-3 whitespace-nowrap py-2 text-xs hover:text-black md:py-0 md:pr-7"
			>
				<img src={country.flag} alt={country.name} class="h-[1.5em]" />
				<span>{country.name}</span>
			</a>
		{/each}
	</div>
</div>

<style lang="scss">
	.arrow-after::after {
		@apply block aspect-square h-[.6125em] content-[''];
	}
</style>
