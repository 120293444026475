<script lang="ts">
	import { onMount } from 'svelte'
	import { page, updated } from '$app/stores'

	export let drawer = false
	export let topOffset = 80
	export let headless = false
	let header: HTMLElement
	let lastScrollTop = 0

	onMount(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop

			if (
				scrollTop > lastScrollTop &&
				scrollTop >= topOffset &&
				!drawer &&
				window.innerWidth < 768
			) {
				header.style.top = `-${topOffset}px`
			} else {
				header.style.top = '0'
			}

			lastScrollTop = scrollTop
		}

		window.addEventListener('scroll', handleScroll)

		// Cleanup function
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})
</script>

<div
	class="relative"
	style:padding-top="{headless ? 0 : topOffset}px"
	data-sveltekit-reload={$updated ? '' : 'off'}
>
	<div
		class="absolute -z-50 h-full w-full"
		style:margin-top="-{headless ? 0 : topOffset}px"
		class:bg-neutral-100={$page.data.layout?.background === 'gray'}
	/>

	<header
		class="header fixed top-0 z-50 h-auto w-screen bg-white"
		bind:this={header}
		bind:offsetHeight={topOffset}
	>
		<slot name="header" />
	</header>

	<main class="min-h-screen">
		<slot />
	</main>

	<footer class="bg-neutral-200">
		<slot name="footer" />
		<!--<div class="page-wrapper py-24">
			<img class="mb-10" src="/images/logos/woltair-red.svg" alt="Logo" />
			<slot name="links" />
			<div class="mb-4 flex w-full flex-wrap gap-5 pt-10">
				{#each docs as doc}
					<a
						class="text-neutral-500 hover:text-neutral-700"
						href={doc.link || doc.link[$page.data.organization.lang]}
						target="_blank"
						rel="noopener noreferrer"
					>
						{doc.title}
					</a>
				{/each}
			</div>
			<p class="pt-2 text-neutral-500">{CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE]?.legalData}</p>
		</div>-->
	</footer>

	<slot name="drawer" />
</div>

<style lang="scss">
	.header {
		transition-property: top;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 700ms;
	}
</style>
