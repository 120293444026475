<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { SOCIAL_MEDIA_LINKS, CONTACT_VARS } from '$lib/consts'
	import { DOCUMENTS, GLOSSARY } from '$lib/routes'
	import CountrySwitch from './CountrySwitch.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let footerLinks: Array
	export let footerDocuments: Array
	export let countries: Object
	export let glossary: Boolean = false

	const socialMediaLinks = SOCIAL_MEDIA_LINKS.map((link) => {
		return {
			...link,
			link: link.url[PUBLIC_WOLTAIR_WEB_CODE],
			icon: link.icons.footer || '/images/icons/linear/link.svg'
		}
	}).filter((link) => link.link)
</script>

<div class="page-fullwidth h-fit overflow-visible">
	<div class="md:page-wrapper page-fullwidth overflow-visible">
		<div
			class="flex h-fit flex-col flex-wrap items-start justify-between overflow-visible border-neutral-300 md:flex-row md:items-center md:gap-10 md:border-b md:py-10"
		>
			<div class="flex w-full flex-col overflow-hidden md:w-fit md:flex-row md:gap-8">
				<!-- links -->
				{#each footerLinks as link, i}
					<a
						href={link.link}
						class="w-full whitespace-nowrap border-neutral-300 p-4 text-xs text-neutral-600 hover:text-neutral-900 md:w-fit md:border-none md:p-0"
						class:border-b={i !== footerLinks.length - 1}>{t(link.text)}</a
					>
				{/each}
				{#if glossary}
					<a
						href="/{GLOSSARY}"
						class="w-full whitespace-nowrap border-t border-neutral-300 p-4 text-xs text-neutral-600 hover:text-neutral-900 md:w-fit md:border-none md:p-0"
						>{t('web.routes.GLOSSARY')}</a
					>
				{/if}
			</div>
			<div class="hidden gap-3 md:flex">
				<!-- socials -->
				{#each socialMediaLinks as link}
					<a href={link.link}><img src={link.icon} alt={link.name} class="min-h-6 h-6" /></a>
				{/each}
			</div>
			<div class="ml-auto mt-4 flex w-full flex-col gap-5 md:mt-0 md:w-fit md:flex-row md:gap-0">
				<div><CountrySwitch {countries} /></div>
				<p class="mx-4 my-3 text-xs text-neutral-500 md:hidden">
					{t('web.global.companyLegalInfo')}
				</p>
				<div class="mx-4 flex flex-wrap items-center gap-4">
					<p class="whitespace-nowrap text-xs font-semibold">
						©{new Date().getFullYear()}
						{CONTACT_VARS[PUBLIC_WOLTAIR_WEB_CODE].companyName}
					</p>
					<div class="ml-auto flex gap-1 md:hidden">
						{#each socialMediaLinks as link}
							<a href={link.link}><img src={link.icon} alt={link.name} class="min-h-6 h-6" /></a>
						{/each}
					</div>
				</div>
			</div>
		</div>
		<div class="mt-8 flex flex-col items-center gap-6 pb-12">
			<p class="mx-4 hidden text-center text-xs text-neutral-500 md:block">
				{t('web.global.companyLegalInfo')}
			</p>

			<div
				class="flex w-full flex-col flex-wrap items-start justify-center gap-5 px-4 md:mx-0 md:w-fit md:flex-row md:items-center md:gap-x-10"
			>
				{#each footerDocuments as document}
					<a
						href={document.link}
						target={document.externalLink ? '_blank' : ''}
						class="text-xs text-neutral-500 hover:text-neutral-800">{t(document.text)}</a
					>
				{/each}
				<a href="/{DOCUMENTS}" class="text-xs text-neutral-500 hover:text-neutral-800"
					>{t('web.routes.DOCUMENTS')}</a
				>
			</div>
		</div>
	</div>
</div>
