<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { BROWSER } from 'esm-env'
	import { onMount, afterUpdate } from 'svelte'
	import IntersectionObserver from 'svelte-intersection-observer'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { PUBLIC_CUSTOMER_ZONE_BASE } from '$env/static/public'

	export let activeSlug = ''
	export let data: Object
	export let drawer: Boolean
	export let navLinks: Array
	export let toggleDrawer: () => void
	let activeEl: Element
	let isVisible = true
	let firstEl: Element
	let firstVisible = true
	let linkAreaWidth: number
	let specialLinkWidth: number
	let firstLinkWidth: number
	let specialAreaWideEnough: Boolean
	let firstAreaWideEnough: Boolean = true
	let menuCont: Element
	let specialEl: Element
	let contTop = 0
	let nonWrapped: Array = []
	let specialElOffset: number

	$: specialAreaWideEnough = linkAreaWidth >= specialLinkWidth + 22

	const lastOffset = (container) => {
		contTop = container.getBoundingClientRect().top
		nonWrapped = []

		const childArr = Array.from(container?.children)

		for (let i = 0; i < childArr.length; i++) {
			if (childArr[i].getBoundingClientRect().top === contTop) {
				nonWrapped.push(childArr[i])
			}
		}

		return (
			container.getBoundingClientRect().right -
			nonWrapped[nonWrapped.length - 1].getBoundingClientRect().right
		)
	}

	const updateOffset = () => {
		specialElOffset = lastOffset(menuCont)
		if (firstVisible) {
			specialEl.style.transform = `translate(-${specialElOffset}px)`
		} else {
			specialEl.style.transform = `translate(0px)`
		}
	}

	onMount(() => {
		//ensures that specialEl (link for active page) is always positioned correctly as last element in menu if its regular element in menu is wrapped
		updateOffset()

		window.addEventListener('resize', () => {
			firstAreaWideEnough = linkAreaWidth >= firstLinkWidth
			updateOffset()
		})
	})

	afterUpdate(() => {
		firstAreaWideEnough = linkAreaWidth >= firstLinkWidth
		updateOffset()
	})

	afterNavigate(() => {
		firstAreaWideEnough = linkAreaWidth >= firstLinkWidth
		updateOffset()
	})

	const phone = t('woltairPhone')

	$: userLoggedIn = BROWSER && $page.data?.user?.name
</script>

<div class="page-fullwidth h-20 transition-shadow" class:shadow={!drawer}>
	<div class="page-wrapper flex h-full items-center justify-start">
		<section class="logo-area flex max-w-[10rem]">
			<a
				href="/"
				on:click={() => {
					if (drawer) toggleDrawer()
				}}
			>
				<img src="/images/logos/woltair-red.svg" alt="Woltair" width="179" height="34" />
			</a>
		</section>

		<section
			class="links-area mx-8-res flex max-h-6 flex-grow overflow-hidden transition-opacity"
			class:opacity-0={drawer}
			class:pointer-events-none={drawer}
			bind:clientWidth={linkAreaWidth}
			class:flex-row-reverse={!firstVisible}
		>
			<div
				class=" shrink flex-wrap items-center justify-start gap-4 overflow-hidden md:mx-0 md:gap-6 {$page.url.pathname.includes(
					PUBLIC_CUSTOMER_ZONE_BASE
				) || activeSlug.length
					? 'flex'
					: 'hidden sm:flex'}"
				bind:this={menuCont}
				class:opacity-0={(!firstVisible && !isVisible) || (!firstVisible && !activeEl)}
				class:pointer-events-none={!firstVisible && !isVisible}
			>
				{#each navLinks as link, i}
					{#if activeSlug === link.slug}
						<!-- if current navLink has same slug as current page, display navLink as active element -->
						<IntersectionObserver element={activeEl} bind:intersecting={isVisible}>
							<button
								class="nav-link nav-link--active opacity-1 cursor-pointer whitespace-nowrap"
								class:nav-link--submenu={link.secondaryLinks}
								on:click={toggleDrawer}
								bind:this={activeEl}
								bind:clientWidth={firstLinkWidth}
								class:opacity-0={!firstAreaWideEnough &&
									navLinks[0].slug === link.slug &&
									window.innerWidth < 450}
								alt={link.text}>{link.text}</button
							>
						</IntersectionObserver>
					{:else if i === 0}
						<!-- if current navLink is the first link, mark it as firstEl, because via first link, we can decide if to display whole basic link area or not (on shrinking viewport) – it will always be the last non-active visible link, so if it doesnt fit, we will hide links container -->
						<IntersectionObserver element={firstEl} threshold={1} bind:intersecting={firstVisible}>
							<a
								class="nav-link whitespace-nowrap hover:!text-primary-500"
								class:pr-10={!firstVisible}
								href="/{link?.slug}"
								bind:this={firstEl}>{link.text}</a
							>
						</IntersectionObserver>
					{:else}
						<!-- render rest of the links that are not active -->
						<a class="nav-link whitespace-nowrap hover:!text-primary-500" href="/{link.slug}"
							>{link?.text}</a
						>
					{/if}
				{/each}
			</div>

			<div
				class="special-link ml-4 shrink-0 md:ml-6"
				class:hidden={isVisible}
				class:!ml-0={!firstVisible}
				bind:clientWidth={specialLinkWidth}
				class:opacity-0={!specialAreaWideEnough}
				bind:this={specialEl}
			>
				{#each navLinks as link}
					{#if activeSlug === link.slug}
						<button
							class="nav-link nav-link--active order-first cursor-pointer whitespace-nowrap sm:order-none"
							class:nav-link--submenu={link.secondaryLinks}
							on:click={toggleDrawer}
							alt={link.text}>{link.text}</button
						>
					{/if}
				{/each}
			</div>
		</section>
		<section class="menu-area flex h-full items-center justify-end gap-4 md:gap-6 xl:gap-8">
			{#if data.featureConfig.phoneNumber}
				<div
					class="phone-wrapper hidden transition-opacity xl:!flex"
					class:opacity-0={drawer}
					class:pointer-events-none={drawer}
				>
					<a
						class="flex w-fit items-center !border-0 !bg-transparent hover:!text-green-800"
						href="tel:{phone}"
						on:click={() => sendGTM('call_click')}
					>
						<div class="mr-3 p-0">
							<img
								class="block h-4 min-w-[1em]"
								src="/images/icons/phone-green.svg"
								alt="Telefon"
								width="21"
								height="21"
							/>
						</div>

						<span class="hidden w-fit whitespace-nowrap text-sm xl:!inline"
							>{t('woltairPhoneFormatted')}</span
						>
					</a>
				</div>
			{/if}

			{#if data.featureConfig.userZone}
				<div
					class="login-wrapper transition-opacity"
					class:opacity-0={drawer}
					class:pointer-events-none={drawer}
				>
					<a
						class="hidden w-fit items-center !border-0 !bg-transparent transition-opacity xl:flex
						{userLoggedIn ? 'text-primary-500' : 'hover:text-primary-500'}"
						href="https://account.{$page.url.hostname.replace('www.', '')}/{userLoggedIn
							? 'orders'
							: 'login'}"
						class:opacity-0={drawer}
						class:pointer-events-none={drawer}
					>
						<div class="rounded-md p-2 xl:mr-3 xl:p-0">
							<img
								class="block h-4 w-fit min-w-[1em]"
								src="/images/icons/icon-login.svg"
								alt="Přihlášení"
								width="73"
								height="78"
							/>
						</div>

						<span class="hidden w-fit whitespace-nowrap text-sm xl:inline">
							{#if userLoggedIn}
								{$page.data.user.name}
							{:else}
								{t('web.routes.login')}
							{/if}
						</span>
					</a>
				</div>
			{/if}

			<div class="menu-wrapper h-fit">
				<button
					data-testid="nav-button"
					class="nav-button flex items-center gap-3"
					on:click={toggleDrawer}
				>
					<div class="nav-icon" class:open={drawer}>
						<span />
						<span />
						<span />
						<span />
					</div>
					<div
						class="relative max-h-5 w-fit items-start justify-center overflow-hidden md:flex {activeSlug.length
							? 'hidden'
							: 'flex'}"
						class:items-end={drawer}
					>
						<div class="flex w-fit flex-col items-start">
							<span class="nav-icon-text text-sm">{t('web.global.navMenu')}</span>
							<span class="nav-icon-text text-sm">{t('web.global.navMenuClose')}</span>
						</div>
					</div>
				</button>
			</div>
		</section>
	</div>
</div>

<style lang="scss">
	.logo-area {
		flex-basis: 15%;
		min-width: 120px;
	}

	.links-area {
		flex-basis: 65%;
	}

	.menu-area {
		flex-basis: 10%;

		@screen xl {
			flex-basis: 35%;
		}
	}

	.nav-link {
		display: flex;
		align-items: center;
		font-size: 14px;
		&--active {
			color: #e20613;
		}

		&--submenu {
			&::after {
				content: '';
				display: block;
				width: 0.8em;
				margin-left: 0.4em;
				height: 1em;
				background-image: url('/images/icons/arrow-down-red.svg');
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
			}
		}
	}

	/////////////

	.nav-icon {
		width: 25px;
		height: 16px;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	.nav-icon span:not(.nav-icon-text) {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: theme('colors.neutral.800');
		border-radius: 5px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
	}

	.nav-button:hover span:not(.nav-icon-text) {
		background: theme('colors.primary-600');
	}

	.nav-icon span:nth-child(1) {
		top: 0px;
	}

	.nav-icon span:nth-child(2),
	.nav-icon span:nth-child(3) {
		top: 7px;
	}

	.nav-icon span:nth-child(4) {
		top: 14px;
	}

	.nav-icon.open span:nth-child(1) {
		top: 8px;
		width: 0%;
		left: 50%;
	}

	.nav-icon.open span:nth-child(2) {
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.nav-icon.open span:nth-child(3) {
		-webkit-transform: rotate(-45deg);
		-moz-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.nav-icon.open span:nth-child(4) {
		top: 8px;
		width: 0%;
		left: 50%;
	}
</style>
