import { initialize } from '@woltair/feature-flags-fe/client'
import { initTranslations } from '@woltair/translations-package-fe'
import { BROWSER } from 'esm-env'
import { building, dev } from '$app/environment'
import { COUNTRY_CODE_A3 } from '$lib/consts'
import { LANGUAGES } from '$lib/consts/languages.js'
import { FEATURE_CONFIG } from '$lib/feature-config'
import { getCookie } from '$lib/utils'
import type { LayoutLoad } from './$types'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

export const load: LayoutLoad = async ({ data, fetch }) => {
	if (BROWSER) {
		console.log(
			`Initializing translations "${LANGUAGES[PUBLIC_WOLTAIR_WEB_CODE as keyof typeof LANGUAGES]}".`
		)
		await initTranslations(
			LANGUAGES[PUBLIC_WOLTAIR_WEB_CODE as keyof typeof LANGUAGES],
			null,
			dev
				? import.meta.env.VITE_TRANSLATIONS_DEV_SERVER_URL
				: import.meta.env.VITE_TRANSLATIONS_SERVER_URL,
			null,
			'web/web',
			false,
			true
		)

		const LDcontext = {
			organization: {
				key: PUBLIC_WOLTAIR_WEB_CODE,
				country: COUNTRY_CODE_A3
			}
		}
		await initialize(LDcontext, import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID)

		if (document.title.startsWith('web.')) {
			// we log this twice because we want to reduce error spaming in Sentry, and we want to have enough informations
			console.log(
				`Missing translation in page ${window.location.host}${window.location.pathname} (${document.title})`
			)
			console.error(`Missing translation of meta title`)
		}
		const cachedUser = localStorage.getItem('cachedUser')
		const czaToken = getCookie('CZA_TOKEN')
		if (!building && FEATURE_CONFIG.userZone && czaToken) {
			let user

			if (cachedUser) {
				user = JSON.parse(cachedUser)
			} else {
				user = await fetch('/api/user').then((r) => r.json())
				if (BROWSER) localStorage.setItem('cachedUser', JSON.stringify(user))
			}

			data = {
				...data,
				user
			}
		} else if (cachedUser) {
			localStorage.removeItem('cachedUser')
		}
	}

	return data
}
