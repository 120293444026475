<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { BROWSER } from 'esm-env'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'

	export let links: Array
	export let activeSlug: String
	export let data: Object
	export let toggleDrawer: Function

	const phone = t('woltairPhone')

	$: userLoggedIn = BROWSER && $page.data?.user?.name
</script>

<div class="flex min-h-fit flex-col gap-6 pb-60">
	{#each links as link}
		<a
			href="/{link.slug}"
			class="flex items-center gap-4 text-xl font-semibold hover:text-primary-500"
			class:text-primary-500={activeSlug === link.slug}
			on:click={toggleDrawer}
		>
			{#if link.icon}
				<img alt={link.text} src={link.icon} class="aspect-square h-[1.25em]" />
			{/if}
			{link.text}</a
		>
	{/each}
	{#if data?.featureConfig.phoneNumber}
		<a
			class="flex w-fit items-center gap-5 !border-0 !bg-transparent"
			href="tel:{phone}"
			on:click={() => sendGTM('call_click')}
		>
			<img
				class="block h-4 w-[1em]"
				src="/images/icons/phone-green.svg"
				alt="Telefon"
				width="21"
				height="21"
			/>

			<span class="w-fit whitespace-nowrap text-green-800">{t('woltairPhoneFormatted')}</span>
		</a>
	{/if}

	{#if data?.featureConfig.userZone}
		<a
			class="flex w-fit items-center gap-5 {userLoggedIn
				? 'text-primary-500'
				: 'hover:text-primary-500'}"
			href="https://account.{$page.url.hostname.replace('www.', '')}/{userLoggedIn
				? 'orders'
				: 'login'}"
			on:click={toggleDrawer}
		>
			<img
				class="block h-4 w-[1em]"
				src="/images/icons/icon-login.svg"
				alt="Přihlášení"
				width="73"
				height="78"
			/>
			<span class="w-fit whitespace-nowrap font-medium">
				{#if userLoggedIn}
					{$page.data.user.name}
				{:else}
					{t('web.routes.login')}
				{/if}
			</span>
		</a>
	{/if}
</div>
