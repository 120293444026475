<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { BROWSER } from 'esm-env'
	import { onMount } from 'svelte'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { saveLeadGTMs } from '$lib/analytics'
	import Drawer from '$lib/components/drawer-2.0/Drawer.svelte'
	import Footer from '$lib/components/footer/Footer.svelte'
	import MessageRibbons from '$lib/components/message-ribbons/MessageRibbons.svelte'
	import Navigation from '$lib/components/navigation/Navigation.svelte'
	import { WEB_TRACKING_ID, FACEBOOK_DOMAIN_VERIFICATION, STEP, ORGANIZATION } from '$lib/consts'
	import AppShell from '$lib/modules/layout-root/AppShell.svelte'
	import Wacp from '$lib/modules/layout-root/Wacp.svelte'
	import {
		ACADEMY,
		CONTACT,
		COOPERATION,
		GRANTS,
		HEAT_PUMP,
		HEAT_PUMP_3050,
		PHOTOVOLTAICS,
		SERVICE,
		FINANCING,
		ABOUT_US,
		CHARGING_STATION,
		JOB_OFFERS,
		BLOG,
		EXPERT_ADVICE,
		MEETING,
		WOLTADVISOR,
		PHOTOVOLTAICS_3050
		//GUARANTEE
	} from '$lib/routes'
	import TranslationLive from '$lib/translation-live/TranslationLive.svelte'
	import { getCookie, setCookie } from '$lib/utils'
	import { removeNBSP } from '$lib/utils'
	import type { LayoutData } from './$types'
	import { PUBLIC_WOLTAIR_WEB_CODE, PUBLIC_ENVIRONMENT } from '$env/static/public'
	import '$lib/styles/app.css'

	let toggleDrawer: () => void
	let drawer: boolean
	let headless: boolean
	export let data: LayoutData

	let showWacpBanner: string | boolean = false
	function hideWacpBanner() {
		showWacpBanner = false
	}
	if (BROWSER) {
		showWacpBanner = getCookie('CM_BANNER') === 'false' ? false : true
		headless = $page.url.searchParams.get('mobile-app') ? true : false
		const utmParamsEntries = [...$page.url.searchParams.entries()].filter(([key]) =>
			key.startsWith('utm_')
		)
		if (utmParamsEntries.length > 0) {
			saveUTMs(utmParamsEntries)
		}
	}
	async function saveUTMs(utmParamsEntries) {
		let anonymUid = getCookie('CM_SESSION')
		if (!anonymUid) {
			anonymUid = await saveLeadGTMs(utmParamsEntries)
			setCookie('CM_SESSION', anonymUid, 0)
		}
	}
	$: if (BROWSER) headless = $page.url.searchParams.get('mobile-app') ? true : false

	const STATIC_TITLE = ' | Woltair'

	const canonicalParams = ['page', 'limit', STEP]

	let topOffset: Number = 80

	let canonicalUrl: string

	$: titleStore = $page.data.meta?.title ?? t('web.meta.general.title')
	$: ogImage = $page.data.meta?.ogImage ?? `/images/og/${$page.data.organization.lang}/default.webp`

	$: title = removeNBSP(titleStore) + STATIC_TITLE
	$: ogImageURL = $page.data.meta?.ogImage?.includes('http')
		? ogImage
		: `${$page.data.organization.url}${ogImage}`
	$: description = $page.data.meta?.description ?? t('web.meta.PUBLIC.meta.description')
	$: noindex = $page.data.meta?.noindex ?? false

	$: activeSlug = getSlug($page.url.pathname)

	const getCanonical = (pageStore: Object, excludeParams: Array, searchParams?: Object) => {
		let parametersString = ''
		let parametersArr: Array = []

		if (searchParams) {
			searchParams.forEach((value, key) => {
				parametersArr.push({ name: key, value: value })
			})
			parametersString = parametersArr
				.filter(({ name }) => excludeParams.includes(name))
				.map(({ name, value }) => `${name}=${value}`)
				.join('&')
		}

		return `${pageStore.url.origin}${pageStore.url.pathname}${
			parametersString.length ? `?${parametersString}` : ''
		}`.replace(/\/$/, '')
	}

	const getSlug = (str: string) => {
		const firstSlashIndex = str.indexOf('/')
		if (firstSlashIndex === -1) {
			return str
		}

		const secondSlashIndex = str.indexOf('/', firstSlashIndex + 1)
		const questionMarkIndex = str.indexOf('?', firstSlashIndex + 1)
		const hashIndex = str.indexOf('#', firstSlashIndex + 1)

		const endIndex = Math.min(
			secondSlashIndex !== -1 ? secondSlashIndex : str.length,
			questionMarkIndex !== -1 ? questionMarkIndex : str.length,
			hashIndex !== -1 ? hashIndex : str.length
		)

		return str.slice(firstSlashIndex + 1, endIndex)
	}

	const checkActiveLink = (lnArr) => {
		let group = ''
		lnArr
			.filter((ln) => ln.secondaryLinks)
			.forEach((ln) => {
				if (
					ln['secondaryLinks']?.some(
						(secLink) => secLink.link === $page.url.pathname && secLink.grouped
					)
				) {
					group = ln.slug
				}
			})
		return group
	}

	/*
		OPTIONS for navLinks:
		slug – basic slug of link category
		text – text of link category
		icon – icon of link category (optional)
		featured – set if link category is featured in menu
		secondaryLinks – array of secondary links (optional)
			– link – full link of secondary link as string (dynamic template string preferred)
			– text – text of secondary link
			– grouped – set if parent link should act as main category for this secondary link (it will be then highlighted inside navigation)
			- featured – set if secondary link is featured in menu
	*/

	const navLinks = [
		{
			slug: HEAT_PUMP,
			text: t('web.routes.HEAT_PUMP'),
			icon: '/images/icons/heat_pump.svg',
			featured: true,
			secondaryLinks: [
				{
					link: HEAT_PUMP_3050 && `/${HEAT_PUMP_3050}`,
					text: t('web.thirty_fifty.nav'),
					grouped: true,
					featured: data.featureConfig.thirtyFifty.hvac
				},
				{
					link: FINANCING && `/${HEAT_PUMP}/${FINANCING}`,
					text: t('web.routes.financing'),
					grouped: true,
					featured: data.featureConfig.financing.hvac
				},
				{
					link: GRANTS && `/${HEAT_PUMP}/${GRANTS}`,
					text: t('web.routes.grants'),
					grouped: true,
					featured: data.featureConfig.grants.hvac
				} /*,
				{
					link: HEAT_PUMP && GUARANTEE && `/${HEAT_PUMP}/${GUARANTEE}`,
					text: t('guarantee'),
					grouped: true,
					featured: data.featureConfig.guarantee.hvac
				},
				{
					link: HEAT_PUMP && `/${HEAT_PUMP}/`,
					text: t('web.global.realizations'),
					grouped: true,
					featured: data.featureConfig.realization.hvac
				}*/
			],
			buttons: [
				{
					text: t('web.routes.woltadvisor'),
					link: WOLTADVISOR && `/${HEAT_PUMP}/${WOLTADVISOR}`,
					featured: data.featureConfig.woltadvisor.hvac,
					primary: true
				},
				{
					text: t('meetings.planMeeting'),
					link: MEETING && `/${HEAT_PUMP}/${MEETING}`,
					featured: data.featureConfig.meeting.hvac,
					primary: false
				}
			]
		},
		{
			slug: PHOTOVOLTAICS,
			text: t('web.routes.PHOTOVOLTAICS'),
			icon: '/images/icons/panel.svg',
			featured: data.featureConfig.header.photovoltaics,
			secondaryLinks: [
				{
					link: PHOTOVOLTAICS_3050 && `/${PHOTOVOLTAICS_3050}`,
					text: t('web.thirty_fifty.nav'),
					grouped: true,
					featured: data.featureConfig.thirtyFifty.pv
				},
				{
					link: FINANCING && `/${PHOTOVOLTAICS}/${FINANCING}`,
					text: t('web.routes.financing'),
					grouped: true,
					featured: data.featureConfig.financing.pv
				},
				{
					link: GRANTS && `/${PHOTOVOLTAICS}/${GRANTS}`,
					text: t('web.routes.grants'),
					grouped: true,
					featured: data.featureConfig.grants.pv
				},
				{
					link: CHARGING_STATION && `/${CHARGING_STATION}`,
					text: t('web.global.chargingStations'),
					grouped: true,
					featured: data.featureConfig.chargingStations
				} /*,
				{
					link: PHOTOVOLTAICS && GUARANTEE && `/${PHOTOVOLTAICS}/${GUARANTEE}`,
					text: t('guarantee'),
					featured: data.featureConfig.guarantee.pv
				} ,
				{
					link: `/${PHOTOVOLTAICS}/`,
					text: t('web.global.realizations'),
					featured: data.featureConfig.realization.pv
				}*/
			],
			buttons: [
				{
					text: t('web.routes.woltadvisor'),
					link: WOLTADVISOR && `/${PHOTOVOLTAICS}/${WOLTADVISOR}`,
					grouped: true,
					featured: data.featureConfig.woltadvisor.pv,
					primary: true
				},
				{
					text: t('meetings.planMeeting'),
					link: MEETING && `/${PHOTOVOLTAICS}/${MEETING}`,
					grouped: true,
					featured: data.featureConfig.meeting.pv,
					primary: false
				}
			]
		},
		{
			slug: GRANTS,
			text: t('web.global.nav.grants'),
			featured:
				data.featureConfig.grants.hvac ||
				data.featureConfig.grants.pv ||
				data.featureConfig.header.grants,
			...(PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ' && {
				secondaryLinks: [
					{
						link: `/${GRANTS}/nova-zelena-usporam`,
						text: 'Nová zelená úsporám',
						grouped: true,
						featured: PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ'
					},
					{
						link: `/${GRANTS}/kotlikove-dotace`,
						text: 'Kotlíkové dotace',
						grouped: true,
						featured: PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ'
					},
					{
						link: `/${GRANTS}/cista-energie-praha`,
						text: 'Čistá energie Praha',
						grouped: true,
						featured: PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ'
					}
				]
			})
		},
		{
			slug: SERVICE,
			text: t('web.global.nav.service'),
			featured: data.featureConfig.service
		},
		{
			slug: BLOG,
			text: t('web.routes.BLOG'),
			featured: data.featureConfig.blog
		},
		{
			slug: COOPERATION,
			text: t('web.routes.COOPERATION'),
			featured: data.featureConfig.cooperation,
			secondaryLinks: [
				{
					link: ACADEMY && `/${ACADEMY}`,
					text: t('web.routes.ACADEMY'),
					grouped: true,
					featured: data.featureConfig.academy
				}
			]
		},
		{
			slug: ACADEMY,
			text: t('web.routes.ACADEMY'),
			featured: data.featureConfig.academy && !data.featureConfig.cooperation
		},
		{
			slug: ABOUT_US,
			text: t('web.routes.ABOUT_US'),
			featured: data.featureConfig.aboutUs,
			secondaryLinks: [
				{
					link: `/${ABOUT_US}`,
					text: t('web.about_us.navText'),
					grouped: true,
					featured: data.featureConfig.academy
				},
				{
					link: CONTACT && `/${CONTACT}`,
					text: t('web.routes.CONTACT'),
					grouped: true,
					featured: true
				},
				{
					link: JOB_OFFERS && `/${JOB_OFFERS}`,
					text: t('web.routes.JOB_OFFERS'),
					grouped: true,
					featured: data.featureConfig.career
				}
			]
		},
		{
			slug: JOB_OFFERS,
			text: t('web.routes.JOB_OFFERS'),
			featured: data.featureConfig.header.career
		}
	]
		.filter((ln) => ln.slug)
		.map((ln) => {
			return {
				...ln,
				secondaryLinks: ln.secondaryLinks?.filter((secLink) => secLink.link)
			}
		}) // remove empty links and empty secondary links

	let activeGroup = checkActiveLink(navLinks)

	const ribbons = [
		{
			locals: ['WOLTAIR_DE'],
			text: '<strong>BEG-Förderungen werden bis Ende 2023 weiterhin durch das BAFA genehmigt:</strong> <br />Sichern Sie sich heute Ihr Angebot und nutzen Sie unseren kostenlosen Förderservice um auch 2024 ohne Risiko installieren können.',
			link: '',
			bgColor: 'bg-primary-600',
			textColor: 'text-neutral-50',
			moreInfo: ''
		},
		{
			locals: ['WOLTAIR_PL'],
			text: 'Ruszamy w Polsce z unikalną promocją 30/50. Kup pompę ciepła w Woltair. Jeśli nie wyrobimy się z montażem w 30 dni, damy Ci 50% rabatu.',
			link: `/${HEAT_PUMP_3050}`,
			bgColor: 'bg-primary-600',
			textColor: 'text-neutral-50'
		},
		{
			locals: ['WOLTAIR_CZ'],
			text: 'Opětovné spuštění příjmu dotací z NZÚ od září. Co se mění? <strong><u>Čtěte zde</u></strong>.',
			link: `/blog/dotace/zname-nove-dotacni-podminky-nzu-co-se-od-zari-zmeni`,
			bgColor: 'bg-primary-600',
			textColor: 'text-neutral-50'
		}
	].filter((rib) => rib.locals.includes(PUBLIC_WOLTAIR_WEB_CODE))

	const scrollSmoothly = (id: string, backToTop: boolean) => {
		if (backToTop) scroll(0, 0)
		setTimeout(() => {
			document.querySelector(id)?.scrollIntoView({
				behavior: 'smooth'
			})
		}, 200)
	}

	const footerLinks = [
		{
			link: CONTACT && `/${CONTACT}`,
			text: 'web.routes.CONTACT',
			featured: true
		},
		{
			link: EXPERT_ADVICE && `/${EXPERT_ADVICE}`,
			text: 'web.routes.EXPERT_ADVICE',
			featured: data.featureConfig.expertAdvice
		},
		{
			link: JOB_OFFERS && `/${JOB_OFFERS}`,
			text: 'web.routes.JOB_OFFERS',
			featured: data.featureConfig.career
		},
		{
			link: ACADEMY && `/${ACADEMY}`,
			text: 'web.routes.ACADEMY',
			featured: data.featureConfig.academy
		}
	].filter((ln) => ln.link)

	const countries = {
		WOLTAIR_CZ: {
			name: 'Česká republika',
			url: 'https://www.woltair.cz',
			flag: '/images/icons/flag/cz.svg'
		},
		WOLTAIR_PL: {
			name: 'Polska',
			url: 'https://www.woltair.pl',
			flag: '/images/icons/flag/pl.svg'
		},
		WOLTAIR_DE: {
			name: 'Deutschland',
			url: 'https://www.woltair.de',
			flag: '/images/icons/flag/de.svg'
		},
		WOLTAIR_IT: {
			name: 'Italia',
			url: 'https://www.woltair.it',
			flag: '/images/icons/flag/it.svg'
		}
	}

	canonicalUrl = getCanonical($page, canonicalParams)

	onMount(() => {
		activeGroup = checkActiveLink(navLinks)
		canonicalUrl = getCanonical($page, canonicalParams, $page.url?.searchParams)
		if (
			!(
				navLinks.some((ln) => ln.slug === activeSlug) &&
				$page.url.pathname.includes(`/${activeSlug}`)
			)
		) {
			activeSlug = activeGroup
		}

		onpopstate = () => {
			if (window.location.hash) {
				scrollSmoothly(window.location.hash, true)
			}
		}
	})

	afterNavigate(() => {
		canonicalUrl = getCanonical($page, canonicalParams, $page.url?.searchParams)
		activeGroup = checkActiveLink(navLinks)

		if (showWacpBanner) {
			showWacpBanner = getCookie('CM_BANNER') === 'false' ? false : true
		}
		if (
			!(
				navLinks.filter((link) => link.featured).some((ln) => ln.slug === activeSlug) &&
				$page.url.pathname.includes(`/${activeSlug}`)
			)
		) {
			activeSlug = activeGroup
		}

		if (window.location.hash) {
			scrollSmoothly(window.location.hash, true)
		}
	})
</script>

<svelte:head>
	{#if PUBLIC_ENVIRONMENT === 'production'}
		{@html `
			<${'script'} type="application/ld+json">
				{
					"@context": "https://schema.org/",
					"@type": "Organization",
					"url": "${ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].webUrl}images/logos/woltair-favicon.webp",
					"logo": "${ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].webUrl}images/logos/woltair-favicon.webp"
				}
			</script>
		`}
	{/if}

	{@html `<${'script'}>
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${WEB_TRACKING_ID[PUBLIC_WOLTAIR_WEB_CODE]}');
  </script>`}
	<title>{title}</title>
	<meta property="og:title" content={title} />
	<meta property="og:image" content={ogImageURL} />
	<meta property="og:site_name" content="Woltair" />
	{#if noindex}
		<meta name="robots" content="noindex" />
	{/if}
	{#if description}
		<meta name="description" content={description} />
		<meta property="og:description" content={description} />
	{/if}
	{#if FACEBOOK_DOMAIN_VERIFICATION[PUBLIC_WOLTAIR_WEB_CODE]}
		<meta
			name="facebook-domain-verification"
			content={FACEBOOK_DOMAIN_VERIFICATION[PUBLIC_WOLTAIR_WEB_CODE]}
		/>
	{/if}

	<meta property="og:url" content={$page.url.href} />
	<link rel="canonical" href={canonicalUrl} />
</svelte:head>

<noscript>
	<iframe
		title="google"
		src="https://www.googletagmanager.com/ns.html?id={WEB_TRACKING_ID[PUBLIC_WOLTAIR_WEB_CODE]}"
		height="0"
		width="0"
		style="display:none;visibility:hidden"
	/>
</noscript>
<AppShell {drawer} bind:topOffset {headless}>
	<svelte:fragment slot="header">
		{#if !headless}
			<MessageRibbons {ribbons} {drawer} />
			<Navigation
				{activeSlug}
				{drawer}
				navLinks={navLinks.filter((link) => link.featured)}
				{toggleDrawer}
				{data}
			/>
		{/if}
	</svelte:fragment>

	<svelte:fragment slot="drawer">
		{#if !headless}
			<Drawer
				{activeSlug}
				bind:drawer
				bind:toggleDrawer
				{topOffset}
				navLinks={navLinks.filter((link) => link.featured)}
				{data}
				currentPath={$page.url.pathname}
			>
				<slot name="links" />
			</Drawer>
		{/if}
	</svelte:fragment>
	<slot />
	<svelte:fragment slot="footer">
		{#if !headless}
			<Footer
				footerDocuments={data.footerDocuments}
				{footerLinks}
				{countries}
				glossary={data.showGlossary}
			/>
		{/if}
	</svelte:fragment>
</AppShell>

{#if showWacpBanner && !$page.data.layout?.cookiesBannerHide}
	<Wacp on:message={hideWacpBanner} />
{/if}
{#if BROWSER}
	<TranslationLive />
{/if}

<style lang="scss">
	:global(.translation-live) {
		position: relative;
	}

	:global(.translation-live span:first-child) {
		background-color: #fff;
		background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"%3E%3Cpath stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 8-8 8v4h4l8-8m-4-4 2.869-2.869.001-.001c.395-.395.593-.593.821-.667a1 1 0 0 1 .618 0c.228.074.425.272.82.666l1.74 1.74c.396.396.594.594.668.822a1 1 0 0 1 0 .618c-.074.228-.272.426-.668.822h0L16 12.001m-4-4 4 4"/%3E%3C/svg%3E');
		display: block;
		box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
		border-radius: 4px;
		width: 16px;
		height: 16px;
		position: absolute;
		left: -12px;
		right: -12px;
		border: 1px solid #e01f26;
		color: #e01f26;
		fill: #e01f26;
		cursor: pointer;
	}
</style>
