<script lang="ts">
	import Ribbon from './Ribbon.svelte'
	export let ribbons: List
	export let drawer: Boolean

	const checkDate = (date: string) => {
		return new Date() <= new Date(date)
	}
</script>

<section class="page-fullwidth flex flex-col">
	{#each ribbons as ribbon}
		{#if ribbon.text && (!ribbon.endDate || checkDate(ribbon.endDate))}
			<Ribbon {ribbon} {drawer} />
		{/if}
	{/each}
</section>
