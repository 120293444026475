<script lang="ts">
	/**
	 * API docs: https://developer.crowdin.com/enterprise/api/v2/
	 */
	import { t } from '@woltair/translations-package-fe'
	import { DEV } from 'esm-env'
	import { fly } from 'svelte/transition'
	import { default as Input } from '$lib/translation-live/Input.svelte'
	import { default as Textarea } from '$lib/translation-live/Textarea.svelte'

	const apiURL = `https://woltair.api.crowdin.com/api/v2`
	const jsonURL = DEV
		? import.meta.env.VITE_TRANSLATIONS_DEV_SERVER_URL
		: import.meta.env.VITE_TRANSLATIONS_SERVER_URL
	const projectId = 26

	let show = false
	let translationKey = ''
	let translationValues = {}
	let translationCurrentText = ''
	let translationCrowdInText = ''
	let translationElement: Element | null = null
	let translationStringId: string | null = null
	let accessToken = localStorage.getItem('crowdinAccessToken') ?? ''
	let accessTokenValid = false

	const language = window.language

	function crowdInFetch(input: RequestInfo | URL, init: RequestInit = {}) {
		return fetch(input, {
			...init,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-type': 'application/json'
			}
		})
	}

	async function getTranslationsICU() {
		if (window.translationsICU) {
			return window.translationsICU
		}

		const result = await fetch(`${jsonURL}/${language}/web/webICU.json`)
		window.translationsICU = await result.json()

		return window.translationsICU
	}

	async function getTranslationsText() {
		show = true
		const searchParams = new URLSearchParams({
			filter: translationKey,
			scope: 'identifier',
			limit: 1
		})

		const resultSource = await crowdInFetch(
			`${apiURL}/projects/${projectId}/strings?${searchParams.toString()}`
		)

		if (resultSource.status === 401) {
			alert(
				`ERROR: You are not correctly authorized. Please remove yours access token and log again.`
			)
			accessTokenValid = false
		} else {
			accessTokenValid = true
		}

		const dataSource = await resultSource.json()

		if (dataSource.data?.[0]?.data) {
			translationStringId = dataSource?.data[0].data.id || null
			const translationSearchParams = new URLSearchParams({
				// stringId: dataSource.data[0].data.id,
				// languageId: 'cs',
				stringIds: [translationStringId]
			})

			const resultTranslation = await crowdInFetch(
				`${apiURL}/projects/${projectId}/languages/${language}/translations?${translationSearchParams}`
			)

			const dataTranslation = await resultTranslation.json()

			if (dataTranslation.data?.[0].data) {
				translationCrowdInText = dataTranslation.data[0].data.text
			} else {
				translationCrowdInText = '---'
				console.warn(`ERROR: Did not find translation to "${language}" for key ${translationKey}.`)
			}
		} else {
			translationCrowdInText = '---'
			console.warn(`ERROR: Did not find source text for key ${translationKey}.`)
		}
	}

	async function translationEditHandler(event: CustomEvent) {
		translationKey = event.detail.key
		translationCurrentText = (await getTranslationsICU())[event.detail.key]
		translationValues = JSON.parse(event.detail.values.replaceAll('|', '"'))
		translationElement = event.detail.element.parentElement.querySelector('span:last-child')

		await getTranslationsText()
	}

	function translationEditChange(e: Event) {
		translationElement.innerHTML = t(e.target!.value, translationValues)
	}

	function handleLoginSubmit(e) {
		e.preventDefault()
		const formData = new FormData(e.target)

		if (!formData.get('accessToken')) {
			alert(`Missing access token`)
			return
		}

		accessToken = String(formData.get('accessToken'))
		localStorage.setItem('crowdinAccessToken', accessToken)

		accessTokenValid = true

		getTranslationsText()
	}

	async function handleEditSubmit(e: Event) {
		e.preventDefault()
		const formData = new FormData(e.target)

		const resultTranslation = await crowdInFetch(`${apiURL}/projects/${projectId}/translations`, {
			method: 'POST',
			body: JSON.stringify({
				stringId: translationStringId,
				languageId: language,
				text: formData.get('translation')
			})
		})

		if (!resultTranslation.ok) {
			alert(`ERROR: Can not update translation of "${translationKey}".`)

			return
		}

		const data = await resultTranslation.json()

		if (data.errors && data.errors[0]?.error?.errors[0]?.message) {
			alert(`ERROR: ${data.errors[0].error.errors[0].message}`)

			return
		}

		// get list of approvals
		const resultListApprovals = await crowdInFetch(
			`${apiURL}/projects/${projectId}/approvals?stringId=${translationStringId}&languageId=${language}`
		)

		if (!resultListApprovals.ok) {
			alert(`ERROR: Can get list approvals for "${translationKey}".`)
			return
		}

		const listApprovals = await resultListApprovals.json()

		// add approval
		const resultAddApproval = await crowdInFetch(`${apiURL}/projects/${projectId}/approvals`, {
			method: 'POST',
			body: JSON.stringify({
				translationId: data!.data?.id
			})
		})

		if (!resultAddApproval.ok) {
			alert(`ERROR: Can approve translation of "${translationKey}".`)
			return
		}

		// remove all old approvals
		for (const approval of listApprovals.data) {
			const resultRemoveApproval = await crowdInFetch(
				`${apiURL}/projects/${projectId}/approvals/${approval.data.id}`,
				{
					method: 'DELETE'
				}
			)

			if (!resultRemoveApproval.ok) {
				console.warn(`WARN: Could not remove old approvals for "${translationKey}".`)
				return
			}
		}

		alert(`Translation updated.`)
	}

	async function handleRemoveAccessToken(e: Event) {
		e.preventDefault()
		localStorage.removeItem('crowdinAccessToken')
		accessToken = ''
		accessTokenValid = false
	}
</script>

<svelte:window on:translation-edit={translationEditHandler} />

{#if show}
	<div class="panel" class:panel-login={!accessToken} transition:fly={{ y: 200, duration: 2000 }}>
		{#if !accessToken}
			<div class="panel-wrap">
				<p><strong>You need to login.</strong></p>

				<p>
					Documentation is in <a href="https://woltair.atlassian.net/l/cp/g1NSoxdn" target="_blank"
						>Confluence</a
					>
				</p>

				<form on:submit={handleLoginSubmit}>
					<Input value="" name="accessToken" type="text" label="Access token" />

					<div class="panel-buttons">
						<button type="submit" class="btn btn-filled-primary text-xs-res">Submit</button>

						<button type="button" on:click={() => (show = false)} class="btn close cursor-pointer">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-x"
								viewBox="0 0 16 16"
							>
								<path
									d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
								/>
							</svg>
						</button>
					</div>
				</form>
			</div>
		{:else}
			<form class="panel-wrap" on:submit={handleEditSubmit}>
				<div class="panel-edit">
					<Textarea
						name="translation"
						rows="2"
						value={translationCurrentText}
						on:input={translationEditChange}
					/>
					<div class="texts">
						<p class="space">
							<strong>Key:</strong>
							{translationKey}

							{#if translationValues}
								<strong>Values:</strong>
								{JSON.stringify(translationValues)}
							{/if}
						</p>
						<p class="space">
							<strong>Current translation:</strong>
							{translationCurrentText}
						</p>
						<p>
							<strong>Translation from CrowdIn:</strong>
							{translationCrowdInText}
							(
							<a
								href="https://woltair.crowdin.com/proofread/537320487b0de7bfce4d989d726ca31d/634/en-${language}/170?filter=basic&value=3#q={translationKey}"
								target="_blank"
							>
								Edit in CrowdIn
							</a>
							)
						</p>
						{#if !accessTokenValid}
							<p>
								Your access token is invalid.
								<a href="#removeAccessToken" on:click={handleRemoveAccessToken}>
									Click here to remove the access token
								</a>
								.
							</p>
						{/if}
					</div>
				</div>

				<div class="panel-buttons">
					<button type="submit" class="btn btn-filled-primary text-xs-res">Submit</button>

					<button type="button" on:click={() => (show = false)} class="btn close cursor-pointer">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							class="bi bi-x"
							viewBox="0 0 16 16"
						>
							<path
								d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
							/>
						</svg>
					</button>
				</div>
			</form>
		{/if}
	</div>
{/if}

<style lang="scss">
	p {
		margin: 0;
	}

	.panel {
		box-shadow: 0 4px 16px 0 rgba(185, 185, 185, 0.5);
		border-top: 1px solid #d8d8d8;
		background-color: #fff;
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 10px;
		z-index: 100000000;

		a {
			text-decoration: underline;
			color: #e20613;
		}
	}

	.panel-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.panel-wrap form {
		flex: auto 1 1;
		display: flex;
		align-items: end;
		gap: 10px;
	}

	.panel-login {
		flex-direction: column;

		.panel-buttons {
			align-items: flex-end;
		}

		.panel-wrap {
			display: block;
		}
	}

	.texts {
		p {
			display: block;
		}

		.space {
			margin-bottom: 5px;
		}
	}

	.panel-edit {
		flex: 1 1 auto;
	}

	.panel-buttons {
		flex: 0 1 auto;
		align-items: flex-start;
		display: flex;
		gap: 5px;
	}

	.close {
		background-color: #e5e5e5;
		padding: 5px;

		svg {
			height: 35px;
			width: 35px;
		}
	}
</style>
