<script lang="ts">
	export let link: Object
	export let toggleDrawer: Function
	export let currentPath: String

	//
</script>

<div class="mb-12 flex flex-col gap-2">
	<a
		href="/{link.slug}"
		on:click={toggleDrawer}
		class="unstyled mb-1 flex items-center gap-3 text-xl font-semibold !text-primary-500"
	>
		{#if link.icon}
			<img alt={link.text} src={link.icon} class="h-[1.25em]" />
		{/if}
		{link.text}
	</a>
	{#each link.secondaryLinks.filter((l) => l.featured) as secLink}
		<a
			href={secLink.link}
			on:click={toggleDrawer}
			class="text-sm text-neutral-600 hover:text-primary-500"
			class:!text-primary-500={currentPath === secLink.link}>{secLink.text}</a
		>
	{/each}

	{#if link.buttons}
		<div class="mt-3 flex flex-col gap-3">
			{#each link.buttons.filter((btn) => btn.featured) as button}
				<a
					href={button.link}
					on:click={toggleDrawer}
					class="btn w-fit bg-neutral-100 !px-[1em] !py-[.25em] text-sm ring-2 ring-neutral-100 hover:bg-transparent {button.primary
						? 'text-primary-500'
						: 'text-black'}">{button.text}</a
				>
			{/each}
		</div>
	{/if}
</div>
