<script lang="ts">
	import { cubicOut } from 'svelte/easing'
	import { tweened } from 'svelte/motion'
	import { PageLinks, MainLinks } from '.'

	export let drawer: Boolean

	export let activeSlug: String
	export let currentPath: String
	export let navLinks: Array
	export let data: Object
	export let topOffset: Number

	export const toggleDrawer = () => {
		drawer = !drawer

		if (drawer) {
			skewY = tweened(5, {
				duration: 700,
				easing: cubicOut
			})

			height = tweened(0, {
				delay: 200,
				duration: 700,
				easing: cubicOut
			})

			heightBG = tweened(0, {
				delay: 0,
				duration: 700,
				easing: cubicOut
			})

			skewY.set(0)
			height.set(100)
			heightBG.set(100)
		} else {
			height = tweened(100, {
				delay: 0,
				duration: 800,
				easing: cubicOut
			})

			heightBG = tweened(100, {
				delay: 100,
				duration: 800,
				easing: cubicOut
			})

			height.set(0)
			heightBG.set(0)
		}
	}

	let skewY
	let height
	let heightBG
	/*beforeNavigate(() => {
		if (drawer) toggleDrawer()
	})*/
</script>

<section
	style:height={`${$heightBG}%`}
	class="hide-scrollbar fixed z-30 h-screen flex-1 overflow-y-auto overflow-x-hidden"
	style="top: {topOffset}px"
>
	<nav class="drawer-nav hide-scrollbar flex justify-center" style:height={`${$height}vh`}>
		{#if drawer}
			<div
				data-testid="nav-drawer-wrapper"
				class="wrapper mt-4 grid h-fit w-full max-w-4xl px-4 sm:mt-0 sm:grid-cols-2"
			>
				{#if navLinks?.some((link) => link.slug === activeSlug)}
					{#each navLinks as link}
						{#if activeSlug === link.slug && link.secondaryLinks?.some((ln) => ln.featured)}
							<PageLinks {link} {toggleDrawer} {currentPath} />
							<MainLinks
								links={navLinks?.filter((ln) => ln.slug !== activeSlug)}
								{data}
								{toggleDrawer}
								{activeSlug}
							/>
						{:else if activeSlug === link.slug}
							<MainLinks links={navLinks} {data} {toggleDrawer} {activeSlug} />
						{/if}
					{/each}
				{:else}
					<MainLinks links={navLinks} {data} {toggleDrawer} {activeSlug} />
				{/if}
			</div>
		{/if}
	</nav>
	<div
		class="menu-background z-20"
		style:height={`${$heightBG}vh`}
		style:transform={`skewY(${$skewY}deg)`}
	/>
</section>

<style lang="scss">
	section {
		position: fixed;
		left: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
	}

	.drawer-nav {
		position: relative;
		background: #fff;
		width: 100%;
		overflow: scroll;
		height: 0;
		transform-origin: top right;
	}

	.menu-background {
		width: 100%;
		background: #e20613;
		height: 0;
		transform-origin: top right;
	}

	.drawer-nav::-webkit-scrollbar {
		display: none;
	}
</style>
