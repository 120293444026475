<script lang="ts">
	import { onMount } from 'svelte'
	import { beforeNavigate } from '$app/navigation'

	export let ribbon: Object
	let showMore: Boolean
	export let drawer: Boolean
	let showOnMount = false

	$: drawer, closeRibbon()

	onMount(() => {
		showOnMount = true
	})

	beforeNavigate(() => {
		showMore = false
	})

	const closeRibbon = () => {
		if (drawer) {
			showMore = false
		}
	}
</script>

<button
	class="ribbon page-fullwidth max-h-0 {ribbon?.bgColor}"
	class:cursor-default={!ribbon?.moreInfo}
	class:max-h-96={showOnMount}
	on:click={() => {
		if (ribbon?.moreInfo) {
			showMore = !showMore
		}
	}}
>
	<div class="page-wrapper py-2">
		{#if ribbon?.link}
			<a href={ribbon?.link} class="flex justify-center text-xs md:text-sm {ribbon?.textColor}"
				><span class="!leading-tight">
					{@html ribbon?.text}
				</span></a
			>
		{:else}
			<p
				class="!mt-0 flex items-center justify-center gap-4 text-xs md:text-sm {ribbon?.textColor}"
			>
				<span class="!leading-tight">
					{@html ribbon?.text}
				</span>
				{#if ribbon?.moreInfo}
					<span
						class="inline-block {showMore
							? '-rotate-90'
							: 'rotate-90'} select-none text-[1.875em] font-semibold transition-transform"
						>&#8250;</span
					>
				{/if}
			</p>
		{/if}

		{#if ribbon?.moreInfo}
			<div class="moreInfo h-fit max-h-0 overflow-hidden text-xs" class:max-h-96={showMore}>
				<p class="{ribbon?.textColor} !my-4 font-light">{ribbon?.moreInfo}</p>
			</div>
		{/if}
	</div>
</button>

<style lang="scss">
	.moreInfo,
	.ribbon {
		transition: max-height 0.2s ease-in-out;
	}
</style>
